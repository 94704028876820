.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pagination ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
.pagination ul li {
  color: #606e7a;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  padding: 5px;
  margin: 0px 5px;
}

.pagination ul li:hover {
  cursor: pointer;
  color: #fff;
  background-color: #007bff !important;
  border-radius: 5px;
}

.pagination ul .active_list {
  background-color: #007bff !important;
  color: #fff;
  border-radius: 5px;
}

.page_number {
  transform: rotate(315deg);
}

.prev_btn {
  margin-right: 20px;
  cursor: pointer;
}

.next_btn {
  cursor: pointer;
  margin-left: 20px;
}
