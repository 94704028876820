.updateuser_main_wrapper {
  margin-top: 14px;
  padding: 0 100px;
}
.form_wrapper {
  background-color: aliceblue;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  color: #000;
  overflow-x: auto;
}
.header_from {
  padding: 10px;
  color: #000;
  background: #f9f9f9;
  border-radius: 8px;
  font-family: Montserrat;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 33.5px;
  letter-spacing: 0.52px;
}
.form_div {
}
.update_user_form {
  padding: 10px 20px;
}
.input_fields_wrapper {
  display: flex;
  justify-content: space-between;
}
.input_fields_labels {
  display: flex;
  flex-direction: column;
  width: 48%;
}
label {
  font-size: 14px;
  margin-top: 10px;
}
input {
  outline: none;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  padding: 8px;
}
input::placeholder {
  padding-left: 10px;
}
select {
  outline: none;
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  padding: 10px;
}
.gallery_remove_icon {
  position: absolute;
  border: 1px #ccc solid;
  right: 3px;
  background: #fefefe;
}
.gallery_images {
  position: relative;
}
