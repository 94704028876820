.userinfo_wrapper {
  margin-top: 14px;
  color: #000;
  padding: 2.5rem 5.187rem 1.5rem 5.187rem;
  background: #f4f5f7;
}
.userinfo_main_box {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.heading {
  padding: 0.5rem 1rem;
  color: #000;
  background: rgba(0, 0, 0, 0.03);
  border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0;
  border-bottom: 1px solid #dee2e6 !important;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 33.5px; /* 128.846% */
  letter-spacing: 0.52px;
}
.userinfodiv {
  padding: 20px;
}
.userinfodiv .topone {
  display: flex;
  justify-content: space-between;
}

.userinfodiv .topone h2 {
  font-weight: 500;
  font-size: 1.625rem;
}

h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: bolder;
  line-height: normal;
}

.userinfodiv .infobox_wrapper .infobox p {
  font-size: 13px;
  /* font-family: Montserrat; */
  font-weight: lighter;
}

.infobox_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.infobox {
  background: rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  width: 31%;
  text-decoration: none;
  overflow: auto;
}

button {
  margin-top: 15px;
  display: flex;
  width: 152px;
  padding: 10px 5px;
  text-align: center;
  display: flex;
  align-self: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
  border-radius: 8px;
  background: black;
  border: none;
  text-decoration: none;

  margin: 10px 0;
}
a:hover {
  text-decoration: none !important;
}
