.main_header_bar {
  background: #fff;
  width: 100%;
  padding: 0;
  margin: 0;
  color: #000;
}
.header_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  gap: 10px;
}

.img_box {
  display: flex;
  height: 40px;
}
.img_box img {
  border: 2px solid gray;
  border-radius: 40px;
}
