.dashboard_main {
  margin-top: 14px;
  padding: 2.5rem 5.187rem 1.5rem 5.187rem;
  background: #f4f5f7;
}

.top_dashboard_wrapper {
  display: flex;
  justify-content: space-between;
}

.Total_div_box {
  background-image: linear-gradient(to right, #ff5e0029, white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  width: 24%;
  padding: 20px;
  border-radius: 6px;
}
.image_div {
  border-radius: 6px;
  padding: 10px;
  background-color: #fe7555;
  border: 3px solid #ffffff;
  color: white;
}
.right_credits_heading {
  color: #737f8b !important;
  font-size: 12px;
}

.Total_div_box1 {
  background-image: linear-gradient(to right, #39a0e929, white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  width: 24%;
  padding: 20px;
  border-radius: 6px;
}

.image_div1 {
  border-radius: 6px;
  padding: 10px;
  background-color: #39a0e9;
  border: 3px solid #ffffff;
  color: white;
}

.Total_div_box2 {
  background-image: linear-gradient(to right, #29ab9129, white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  width: 24%;
  padding: 20px;
  border-radius: 6px;
}

.image_div2 {
  border-radius: 6px;
  padding: 10px;
  background-color: #29ab91;
  border: 3px solid #ffffff;
  color: white;
}

.Total_div_box3 {
  background-image: linear-gradient(to right, #40518929, white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  width: 24%;
  padding: 20px;
  border-radius: 6px;
}

.image_div3 {
  border-radius: 6px;
  padding: 10px;
  background-color: #405189;
  border: 3px solid #ffffff;
  color: white;
}

.middele_div_wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}
.left_graph_div {
  padding: 10px;
  width: 60%;
  border-radius: 6px;
  min-height: 220px;
  background-color: #fff;
  box-shadow: 0 0 0 0 rgb(90 113 208 / 11%), 0 4px 16px 0 rgb(167 175 183 / 33%);
}
.left_graph_div h4 {
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #1f1f1f;
  margin-bottom: 2px;
}
.right_box_div {
  width: 35%;
  gap: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
}
.order_placed {
  background-image: linear-gradient(to right, #40518929, white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  width: 100%;
  padding: 20px;
  border-radius: 6px;
}
#circle_check {
  display: flex;
  align-items: center;
  justify-content: center;
}
.aplus_div {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image_div4 {
  border-radius: 6px;
  padding: 14px 16px;
  background-color: #3980c0;
  border: 3px solid #ffffff;
  color: white;
}

.botton_div_wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.left_bottom_wrapper {
  padding: 15px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 0 0 0 rgb(90 113 208 / 11%), 0 4px 16px 0 rgb(167 175 183 / 33%);
  width: 48%;
}
.right_bottom_wrapper {
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 0 0 0 rgb(90 113 208 / 11%), 0 4px 16px 0 rgb(167 175 183 / 33%);
  width: 48%;
}
.left_bottom_div {
  display: flex;
  gap: 300px;
}
.left_talbel_date {
  border-bottom: 0;
}
.order_tabel {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 8px;
  text-align: left;
  /* border-bottom: 1px solid #ddd; */
}
.o_n {
  font-weight: 600;
  font-size: 0.875rem;
}
.order_numb_details {
  font-size: 0.812rem;
}

