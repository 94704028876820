* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  display: flex;
}
main {
  padding: 0px;
  width: 100%;
  background: #f4f5f7;
  color: #000;
}

.table thead th {
  border-bottom: 1px solid #dee2e6;
  border-top: none;
}

.table tbody tr {
  border-bottom: 1px solid #dee2e6;
}
.table tbody td {
  padding-top: 20px;
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  background: #000;
  color: white;
  height: 100vh;
  /* overflow-y: auto; */
}

.top_section {
  display: flex;
  background: #fff;
  color: #000;
  justify-content: space-between;
  padding: 10px 10px;
}
.logo {
  font-size: 18px;
  line-height: 0;
}
.bars {
  width: 30px;
  padding: 8px 0;
}
.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}
.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);

  color: white;
}
&:hover {
  a {
    text-decoration: none;
  }
}

.routes {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.routes a {
  cursor: pointer;
}
.link {
  text-decoration: none;
  cursor: pointer;
  display: flex;
  color: white;
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.link:hover {
  cursor: pointer;
  border-right: 4px solid white;
  background: rgba(12, 234, 239, 0.31);
  color: #f9f9f9;
  text-decoration: none;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.active {
  border-right: 4px solid white;
  background: #0ceaef;
}
.link_text {
  cursor: pointer;
  white-space: nowrap;
  font-size: 15px;
  text-decoration: none;
}

.menu {
  display: flex;
  color: white;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu:hover {
  background: #0ceaef;
}
.menu_item {
  display: flex;
  gap: 10px;
}
.menu_container {
  display: flex;
  flex-direction: column;
  border-left: #fff 5px solid;
}
.menu_container .link {
  padding-left: 30px;
}
.modal-backdrop.show {
  opacity: 0.1 !important;
}
/* 

@media (max-width:481px,1023px,767px,1220px) {
  
} */

.Toastify__toast {
  width: 400px;
}
