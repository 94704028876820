@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Manrope", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace, "Manrope";
}

ul {
  list-style: none;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
table {
  font-family: "Manrope", sans-serif;
}

