.make-variations-btn {
  width: 200px;
  /* background-color: #8d918c; */
  font-size: "0.812rem";
  border-radius: "0.3rem";
  font-weight: "300";
  border: "none";
}
.varaintList_wrapper {
  display: flex;
  padding: 0px !important;
}
.variant_item {
  display: flex;
  flex-direction: column;
}

.variant_item {
  margin: 2px 0;
  font-weight: 500;
  color: white;
  border-radius: 4px;
  padding: 2px 5px;
  font-size: 14px;
  margin-bottom: 5px;
  margin-right: 5px;
  background-color: black;
}

.varaint_value {
  display: flex;
  flex-direction: column;
}
