.user_list_wrap {
  margin-top: 14px;
  padding: 0 90px;
}
.all-user {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  color: #000;
  overflow-x: auto;
}

h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.9px;
  text-transform: capitalize;
}

.all-user table {
  width: 100%;
}

.all-user table td {
  /* text-align: center; */
  padding: 5px;
}

.all-user table th {
  padding: 5px;
}

.header {
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 55px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .filter_div {
  display: flex;
  gap: 10px;
}
.add_user_btn {
  padding: 10px;
  border-radius: 4px;
  background: #1da1f2;
  box-shadow: 0px 0px 59px 0px rgba(29, 145, 148, 0.25);
  border: #0ceaef;
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  /* font-style: italic; */
  font-weight: 300;
  line-height: 30px; /* 150% */
  text-transform: uppercase;
  margin: 10px 0;
}
.filter_div {
  font-size: 16px;
  font-weight: 300;
}
.select_div select {
  font-weight: 300;
  border: 1px solid #000;
  padding: 11px 15px;
}

.text label {
  color: white;
}
.action_div {
  display: flex;
  gap: 10px;
  cursor: pointer;
}
.reload_div {
  border: 1px solid #000;
  border-radius: 6px;
  padding: 0 15px;
  display: flex;
  align-items: center;
}
.reload_div a {
  color: #000;
  font-size: 25px;
}
.Users_tabel {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.Users_tabel td,
.Users_tabel th {
  border: 1px solid #ddd;
  padding: 8px;
}

.Users_tabel tr:nth-child(even) {
  background-color: #f2f2f2;
}

.Users_tabel tr:hover {
  background-color: #ddd;
}

.Users_tabel th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0ceaef;
  color: white;
}

.main-panel {
  background-color: #f4f5f7;
  transition: width 0.25s ease, margin 0.25s ease;
  width: 100%;
  min-height: 100vh;

  display: flex;

  flex-direction: column;
}
.tabel_title {
  font-size: 16px;
  font-family: "Manrope", sans-serif;
  font-weight: 500;
}
.master-pages .content-wrapper {
  padding: 1.5rem 5.187rem 1.5rem 5.187rem;
}
.content-wrapper {
  background: #f4f5f7;
  width: 100%;
  flex-grow: 1;
}

.row.tabelhed {
  background-color: #ffffff;
  margin: 0px 0px 10px 0px;
  padding: 25px 25px 25px 20px;
  border-radius: 12px;
}

.search-btn {
  width: 50px;
  border: 1px solid #6c757d;
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  background-color: transparent;
  color: #6c757d;
  margin-top: 0 !important;
}
.tabel_titles {
  font-size: 14px;
}
.health_td {
  width: 30%;
}

.success_btnnn {
  background: #34b1aa;
  color: #ffffff;
  padding: 0.5rem 0.75rem;
  font-size: 0.625rem;
  border-radius: 6px;
  width: 80px;
  margin: auto;
}
.fail_btnnn {
  background: #f95f53;
  color: #ffffff;
  padding: 0.5rem 0.75rem;
  font-size: 0.625rem;
  border-radius: 6px;
  width: 100px;
  margin: auto;
}
